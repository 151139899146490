import Vue from 'vue';
import Vuex from 'vuex';

const requireModule = require.context('./modules', false, /\.js$/);
const modules = {};
requireModule.keys().forEach((fileName) => {
  const moduleName = fileName.replace(/(\.\/|\.js)/g, '');
  modules[moduleName] = requireModule(fileName).default || requireModule(fileName);
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    // 定义你的全局状态
    count: 0,
  },
  mutations: {
    // 定义你的同步变更
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
  },
  actions: {
    // 定义你的异步变更
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit('increment');
      }, 1000);
    },
    decrementAsync({ commit }) {
      setTimeout(() => {
        commit('decrement');
      }, 1000);
    },
  },
  getters: {
    // 定义你的计算属性
    count(state) {
      return state.count;
    },
  },
});