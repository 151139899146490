<template>
  <el-dialog class='dialog-user-in-organization-edit' :title="title" :visible="visible" @update:visible="onClose" width="80%"
    @open="onDialogOpen" top="110px">
    <div class="main">
      <div class="search-table">
        <div class="filters">
          <div></div>
          <div class="right-panel">
            <el-input placeholder="Search Account name" clearable :value="filter.username" @input="delaySearch" />
            <el-button icon="el-icon-search" @click="fetchData">Search</el-button>
          </div>
        </div>
        <el-table class="table-content"
          ref="multipleTable"
          tooltip-effect="dark"
          :border="true"
          :data="tableData.list"
          :cell-style="{ 'font-size': '14px', 'font-weight': 400, 'color': '#888888' }"
          :header-cell-style="{ 'background': '#FAFAFA', 'font-size': '14px', 'font-weight': 500, 'color': 'rgba(0, 0, 0, 0.85)' }"
          @select="handleSelect"
          @select-all="handleSelectAll"
          empty-text="Empty" v-loading="isFetching">
          <el-table-column type="selection" width="40" :align="'center'" />
          <el-table-column prop="id" label="ID" width="120" :align="'center'" />
          <el-table-column prop="username" label="Account name" />
          <el-table-column prop="alias" label="Alias" />
          <el-table-column prop="email" label="Email" />
        </el-table>
        <div class="pagination-wrap">
          <div class="table-text"><b>{{ tableData.totalCount }}</b>&nbsp;users</div>
          <el-pagination class="page-index" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page="tableData.currentPage" :page-size="tableData.pageSize" :total="tableData.totalCount" />
        </div>
      </div>
      <div v-loading="isFetchingUsers" class="users" :class="{ 'empty': multipleSelection.length == 0 }">
        <template v-if="multipleSelection.length > 0">
          <el-tag closable v-for="user of multipleSelection" :key="user.email" @close="unselectUser(user)">{{ user.username }}</el-tag>
        </template>
        <template v-else>
          <div type="info"><span>Please select users from the left table</span></div>
        </template>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isSubmitting'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getAdminPlatformUsers, updateUsersInOrganization, getUsersInOrganization } from "@/api";

export default {
  props: ["visible", "value", "confirm"],

  data() {
    return {
      showUserDialog: false,
      showOrganizationDialog: false,
      editFormData: {},
      filter: {
        status: null,
        username: "",
      },
      tableData: {
        currentPage: 1,
        list: [],
        pageSize: 10,
        totalCount: 0,
      },
      multipleSelection: [],
      isFetching: false,
      isSubmitting: false,
      isFetchingUsers: false,
      delayTimer: null,
    };
  },
  computed: {
    title() {
      return `User In Organization(${this.value.name})`;
    },
  },
  methods: {
    onDialogOpen() {
      this.filter.username = "";
      this.tableData.currentPage = 1;
      this.$nextTick(() => {
        this.fetchData();
        this.fetchSelectedUsersFromServer();
      });
    },
    unselectUser(user) {
      this.multipleSelection = this.multipleSelection.filter(
        (selected) => selected.email !== user.email
      );
      const tableItem = this.tableData.list.find((item) => item.email === user.email);
      if (tableItem) {
        this.$refs.multipleTable.toggleRowSelection(tableItem, false);
      }
    },
    delaySearch(username) {
      this.filter.username = username;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.tableData.currentPage = 1;
        this.fetchData();
      }, 500);
    },
    async fetchData() {
      try {
        this.isFetching = true;
        const userPageData = await getAdminPlatformUsers({
          status: this.filter.status,
          username: this.filter.username,
          offset: this.tableData.currentPage,
          limit: this.tableData.pageSize,
        });
        this.tableData.list = userPageData.list;
        this.tableData.totalCount = userPageData.totalCount;
        this.restoreSelection();
      } finally {
        this.isFetching = false;
      }
    },
    handleCurrentChange(page) {
      this.tableData.currentPage = page;
      this.fetchData();
    },
    handleSelect(selection, row) {
      const index = this.multipleSelection.findIndex((selected) => selected.email === row.email);
      if (index === -1) {
        this.multipleSelection.push(row);
      } else {
        this.multipleSelection.splice(index, 1);
      }
    },
    handleSelectAll(selection) {
      if (selection.length === 0) {
        this.multipleSelection = this.multipleSelection.filter(
          (selected) => !this.tableData.list.find((item) => item.email === selected.email)
        );
      } else {
        selection.forEach((item) => {
          const index = this.multipleSelection.findIndex((selected) => selected.email === item.email);
          if (index === -1) {
            this.multipleSelection.push(item);
          }
        });
      }
    },
    updateMultipleSelection() {
      this.multipleSelection = [
        ...new Map(
          [...this.multipleSelection, ...this.multipleSelection].map((item) => [
            item.email,
            item,
          ])
        ).values(),
      ];
    },
    restoreSelection() {
      this.$nextTick(() => {
        this.multipleSelection.forEach((selected) => {
          const match = this.tableData.list.find(
            (item) => item.email === selected.email
          );
          if (match) {
            this.$refs.multipleTable.toggleRowSelection(match, true);
          }
        });
      });
    },
    async fetchSelectedUsersFromServer() {
      try {
        this.isFetchingUsers = true;
        const users = await getUsersInOrganization(this.value.id);
        this.multipleSelection = users;
      } finally {
        this.isFetchingUsers = false;
      }
    },
    async submitForm() {
      try {
        this.isSubmitting = true;
        await updateUsersInOrganization(this.value.id, this.multipleSelection);
        this.$emit("confirm", this.multipleSelection);
        this.onClose();
        this.$message.success("Update user in organization successfully");
      } finally {
        this.isSubmitting = false;
      }
    },
    onClose() {
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-user-in-organization-edit {
  text-align: left;

  .el-dialog__header {
    padding-top: 30px;
  }

  .el-dialog__body {
    padding-bottom: 0;
    padding-top: 17px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-dialog__footer {
    padding-bottom: 30px;
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .search-table {
      flex: 2;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }

    .users {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      justify-items: flex-start;

      &.empty {
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
      }
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >div {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .pagination-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}
</style>