import {
  getOrganizations,
  createOrUpdateOrganization,
  deleteOrganization,
} from "@/api";

export default {
  namespaced: true,
  state: {
    organizations: [],
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations;
    },
    UPDATE_ORGANIZATION(state, organization) {
      const index = state.organizations.findIndex(
        (org) => org.id === organization.id
      );
      if (index !== -1) {
        state.organizations.splice(index, 1, organization);
      }
    },
    DELETE_ORGANIZATION(state, organization) {
      state.organizations = state.organizations.filter(
        (org) => org.id !== organization.id
      );
    },
  },
  actions: {
    async fetchOrganizations({ commit }) {
      console.log("fetchOrganizations");
      const organizations = await getOrganizations();
      commit("SET_ORGANIZATIONS", organizations);
    },
    async addOrUpdateOrganization({ commit, dispatch }, organization) {
      await createOrUpdateOrganization(organization);
      if (organization.id !== undefined) {
        commit("UPDATE_ORGANIZATION", organization);
      } else {
        await dispatch("fetchOrganizations");
      }
    },
    checkNameExists({ state }, name) {
      return state.organizations.some((org) => org.name === name);
    },
    getOrganizationByName({ state }, name) {
      return state.organizations.find((org) => org.name === name);
    },
    async deleteOrganization({ commit }, organization) {
      await deleteOrganization(organization.id);
      commit("DELETE_ORGANIZATION", organization);
    },
  },
  getters: {
    organizations: (state) => state.organizations || [],
    selectedOrganization: (state) => state.selectedOrganization,
  },
};
