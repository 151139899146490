<template>
  <div class="home-page">
    <div class="header">
      <div class="left-area">
        <img class="logo" src="../assets/logo.png" />
        <div class="logo-title">Admin Portal</div>
      </div>
      <el-link class="logout" type="info" @click="handleLogout">Log Out</el-link>
    </div>
    <div class="main">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="User" name="first">
          <UserList />
        </el-tab-pane>
        <el-tab-pane label="Organization" name="second" lazy>
          <OrganizationList />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/event-bus.js";
import { MessageBox } from 'element-ui'
import UserList from './components/UserList.vue'
import OrganizationList from './components/OrganizationList.vue'
export default {
  components: {
    UserList,
    OrganizationList
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    async handleLogout() {
      try {
        await MessageBox.confirm('Are you sure you want to log out?', 'Confirm Logout', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        });
        EventBus.$emit('logout');
      } catch (error) {
        // User canceled the logout
      }
    },
  }
}
</script>
<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 0 120px;
  height: 64px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  z-index: 1;
}

.header .left-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 46px;
  height: 46px;
}

.logo-title {
  left: 165px;
  font-size: 19px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.logout {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
}

.main {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.main>.el-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main>.el-tabs> :deep(.el-tabs__content) {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main>.el-tabs> :deep(.el-tabs__content>.el-tab-pane) {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
