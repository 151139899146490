<template>
  <el-dialog class='dialog-organization-edit' :title="title" :visible="visible" @update:visible="onClose" width="500px"
    @open="onDialogOpen" top="110px">
    <el-form :model="formData" :rules="rules" ref="editForm" label-position="left" label-width="140px">
      <el-form-item prop="name" label="Name:">
        <el-input placeholder="Type Organization name" v-model="formData.name" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
const RESTORE_TIP = 'The organization name has already been created and deleted. Continuing with this name will restore the previous organization; otherwise, please choose a different name.';

export default {
  props: ['visible', "value", 'confirm'],
  data() {
    const me = this;
    return {
      isLoading: false,
      formData: {
        name: '',
      },
      rules: {
        name: [{
          type: 'string',
          required: true,
          message: 'Organization name is required',
          trigger: 'blur'
        },
        {
          validator: async (rule, name, callback) => {
            if (!name) {
              callback(new Error('Organization name is required'));
            } else {
              const exists = await me.getOrganizationByName(name);
              if (exists && exists.isDeleted) {
                callback(new Error(RESTORE_TIP));
              }
              else if (exists) {
                callback(new Error('Organization name already exists'));
              } else {
                callback();
              }
            }
          },
          trigger: ['blur']
        }],
      },
      expirationTimeValue: '',
    };
  },
  computed: {
    title() {
      return (this.value.id ? 'Edit' : 'Add') + ' Organization';
    },
  },
  methods: {
    ...mapActions('organization', ['addOrUpdateOrganization', 'getOrganizationByName']),
    onDialogOpen() {
      this.formData = Object.assign({}, this.value)
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    async submitForm() {
      try {
        await this.$refs.editForm.validate();
      } catch (error) {
        if (typeof error.name[0] && error.name[0].message == (RESTORE_TIP)) {
          this.formData.isDeleted = false;// restore
        }
        else {
          return;
        }
      }

      this.isLoading = true;
      try {
        await this.addOrUpdateOrganization(this.formData);
      }
      catch (error) {
        this.$message.error(error.message);
      }
      finally {
        this.isLoading = false;
      }
      this.$emit('confirm', this.formData);
      this.onClose();
      this.$message.success('success');
    },
    onClose() {
      this.$refs.editForm.clearValidate();
      this.$emit('update:visible', false);
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-organization-edit {
  text-align: left;

  .el-dialog__header {
    padding-top: 30px;
  }

  .el-dialog__body {
    padding-bottom: 0;
    padding-top: 17px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .el-dialog__footer {
    padding-bottom: 30px;
  }
}
</style>