<template>
  <div class="user-list">
    <div class="table-card">
      <div class="table-header-section">
        <div></div>
        <div class="filters">
          <div>
            <span>Status:</span>
            <el-select v-model="filter.status" placeholder="Select">
              <el-option
                v-for="item of [{ 'id': null, 'name': 'All' }, { 'id': 'Active', 'name': 'Active' }, { id: 'Inactive', name: 'Inactive' },]"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </div>
          <div>
            <el-input placeholder="Search Account name" clearable :value="filter.username" @input="delaySearch" />
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <el-table class="table-content"
          :border="true"
          :data="displayTableData"
          height="auto"
          :cell-style="{ 'font-size': '14px', 'font-weight': 400, 'color': '#888888' }"
          :header-cell-style="{ 'background': '#FAFAFA', 'font-size': '14px', 'font-weight': 500, 'color': 'rgba(0, 0, 0, 0.85)' }"
          empty-text="Empty" v-loading="loadingUsers">
          <el-table-column prop="id" label="ID" width="120" :align="'center'" />
          <el-table-column prop="username" label="Account name" />
          <el-table-column prop="alias" label="Alias" />
          <el-table-column prop="email" label="Email" width="280" />
          <el-table-column prop="status" label="Status">
            <template slot-scope="scope">
              <div class="indicator-wrapper">
                <div class="status-indicator-inactive"
                  v-if="scope.row.status.substring(0, 7) === 'expired'"></div>
                <div class="status-indicator-active"
                  v-else-if="scope.row.status === 'active'">
                </div>
                <div class="status-indicator-inactive"
                  v-else-if="scope.row.status === 'inactive'"></div>
                <div class="status-indicator-pending"
                  v-else-if="scope.row.status === 'pending'"></div>
                <div class="status-text">{{ getStatusText(scope.row.status) }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="dataPoints" label="Max Data Points" align="center" />
          <el-table-column prop="creationTime" label="Creation Time" />
          <el-table-column prop="expirationTime" label="Expiration Time" />
          <el-table-column label="Operations" width="220">
            <template slot-scope="scope">
              <div class="operation-wrap">
                <el-button class="status-operation-button" @click="updateStatus(scope.row)" type="text" size="small">{{ getStatusButtonText(scope.row.status) }}</el-button>
                <div class="button-divider"></div>
                <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
                <div class="button-divider"></div>
                <el-button class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <div class="table-text"><b>{{ userPageData.totalCount }}</b>&nbsp;users</div>
          <el-pagination class="page-index" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page.sync="currentPage"  :page-size="pageSize" :total="totalItems" />
        </div>
      </div>
    </div>
    <DialogUserEdit :visible.sync="showUserDialog" :value="editFormData" @confirm="dialogEditHandle" />
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import { getAdminPlatformUsers, updateStatus, deleteUser } from '@/api'
import DialogUserEdit from '@/components/DialogUserEdit.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    DialogUserEdit,
  },
  data() {
    return {
      showUserDialog: false,
      showOrganizationDialog: false,
      editFormData: {},
      filter: {
        status: null,
        username: "",
      },
      userPageData: {
        currPage: 1,
        list: [], //Users
        pageSize: 10,
        totalCount: 0,
        totalPage: 0,
      },
      adminSites: null,
      currentPage: 1,
      pageSize: 10,
      loadingUsers: false,
      delayTimer: null
    }
  },
  computed: {
    ...mapGetters('organization', ['organizations']),
    totalItems() {
      return this.userPageData.totalCount
    },
    displayTableData: function () {
      let currentPageUsers = this.deepClone(this.userPageData.list)
      currentPageUsers.map(user => {
        let expiredTime = new Date(user.expirationTime)
        const nowTime = new Date()
        if (expiredTime < nowTime) {
          user.status = 'expired_' + user.status
        }
        user.creationTime = this.formatTime(user.creationTime)
        user.expirationTime = this.formatTime(user.expirationTime)
        return user
      })
      return currentPageUsers
    }
  },
  watch: {
    "filter.status": {
      handler: function () {
        this.loadUsers()
      },
    }
  },
  methods: {
    delaySearch(username) {
      this.filter.username = username
      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
        this.loadUsers()
      }, 500)
    },
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange() {
      this.loadUsers()
    },
    async loadUsers() {
      this.loadingUsers = true
      try {
        const userPageData = await getAdminPlatformUsers({ status: this.filter.status, username: this.filter.username, offset: this.currentPage, limit: this.pageSize })
        if (userPageData == null) return;
        this.currentPage = userPageData.currPage;

        if (userPageData.list == null) {
          userPageData.list = []
        }
        else {
          this.userPageData = userPageData
        }
      } catch (err) {
        this.defaultErrorHandling(err)
      } finally {
        this.loadingUsers = false
      }
    },
    updateStatus(user) {
      let status
      if (user.status.substring(0, 7) !== 'expired') {
        status = user.status === 'active' ? 'inactive' : 'active'
      } else {
        status = user.status.substring(8) === 'active' ? 'inactive' : 'active'
      }
      updateStatus(user.id, status).then(() => {
        this.loadUsers()
      })
    },
    handleEdit(row) {
      let index = (this.userPageData.list || []).findIndex((x) => x.id === row.id);
      let editItem = this.userPageData.list[index]
      Object.assign(this.editFormData, editItem)
      this.showUserDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        console.log('delete confirm.');
        await deleteUser(row.id);
        this.$message.success({ center: true, message: 'success' });
        this.loadUsers();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    dialogEditHandle() {
      this.loadUsers()
    },
    
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },
    deepClone(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
  },
  async mounted() {
    await this.loadUsers();
  }
}
</script>
<style scoped>
.table-content::before {
  height: 0px;
}

.indicator-wrapper {
  display: flex;
  align-items: center;
}

.status-text {
  margin-left: 10px;
}

.status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}

.user-list {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.table-card {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 20px 0;
}

.table-card .operation-panel {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-title {
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.username-text {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.update-button {
  margin-left: 37px;
  width: 88px;
  height: 36px;
  padding-top: 0;
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
  color: #555555;
}

.table-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

.table-header-section .filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  color: rgba(86, 94, 109, 1);
}

.table-header-section .filters>div>span {
  margin-right: 14px;
}

.table-text {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}

.table-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 0 20px;
  overflow: hidden;
}

.table-content {
  flex: 1;
}

.operation-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.status-operation-button {
  width: 70px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.operation-button {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.button-divider {
  width: 1px;
  height: 14px;
  background: #E9E9E9;
  margin-left: 14px;
  margin-right: 14px;
}

.pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px;
}
</style>
