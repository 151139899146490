<template>
  <div class="organization-list">
    <div class="table-card">
      <div class="table-header-section">
        <div></div>
        <div class="filters">
          <label for="status" class="status">
            <span>Status:</span>
            <el-select id="status" v-model="filter.status" placeholder="Select">
              <el-option
                v-for="item of [{ 'id': null, 'name': 'All' }, { 'id': 'Active', 'name': 'Active' }, { id: 'Inactive', name: 'Inactive' },]"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </label>
          <el-button icon="el-icon-refresh" @click="fetchData">Refresh</el-button>
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">Add Organization</el-button>
        </div>
      </div>
      <div class="table-wrap">
        <el-table class="table-content"
          :data="paginatedData"
          :border="true"
          height="auto"
          :cell-style="{ 'font-size': '14px', 'font-weight': 400, 'color': '#888888' }"
          :header-cell-style="{ 'background': '#FAFAFA', 'font-size': '14px', 'font-weight': 500, 'color': 'rgba(0, 0, 0, 0.85)' }"
          empty-text="Empty" v-loading="loading">
          <el-table-column prop="id" label="ID" width="220" :align="'center'" />
          <el-table-column prop="name" label="Name" />
          <el-table-column prop="manager" label="Manager" />
          <el-table-column prop="creationTime" label="Creation Time">
            <template slot-scope="scope">
              <span>{{ scope.row.creationTime | formatDate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Operations" width="280">
            <template slot-scope="scope">
              <div class="operation-wrap">
                <el-button class="operation-button" @click="handleUser(scope.row)" type="text" size="small">Users</el-button>
                <div class="button-divider"></div>
                <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
                <div class="button-divider"></div>
                <el-button v-if="scope.row.isDeleted" class="operation-button" @click="handleRestore(scope.row)" type="text" size="small">Restore</el-button>
                <el-button v-else class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>

              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap">
          <div class="table-text"><b>{{ totalCount }}</b>&nbsp;organizations</div>
          <el-pagination class="page-index" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="totalCount" />
        </div>
      </div>
    </div>
    <DialogOrganizationEdit :visible.sync="showOrganizationDialog" v-model="editFormData" />
    <DialogUserInOrganizationEdit :visible.sync="showUserDialog" :value="editFormData" />
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import { mapGetters, mapActions } from 'vuex'
import DialogOrganizationEdit from '@/components/DialogOrganizationEdit.vue'
import DialogUserInOrganizationEdit from './DialogUserInOrganizationEdit.vue'

export default {
  components: {
    DialogOrganizationEdit,
    DialogUserInOrganizationEdit,
  },
  data() {
    return {
      showUserDialog: false,
      showOrganizationDialog: false,
      filter: {
        status: null,
      },
      editFormData: {},
      currentPage: 1,
      pageSize: 10,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('organization', ['organizations']),
    totalCount() {
      return this.list.length;
    },
    list() {
      return this.organizations || [];
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.list.slice(start, end);
    },
  },
  methods: {
    ...mapActions('organization', ['fetchOrganizations', 'addOrUpdateOrganization', 'deleteOrganization']),
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    async fetchData() {
      this.loading = true
      await this.fetchOrganizations();
      this.loading = false
    },
    handleUser(row) {
      this.editFormData = row;
      this.showUserDialog = true;
    },
    handleAdd() {
      this.editFormData = {}
      this.showOrganizationDialog = true;
    },
    handleEdit(row) {
      let index = (this.organizations || []).findIndex((x) => x.id === row.id);
      let editItem = this.organizations[index]
      this.editFormData = { ...editItem };
      this.showOrganizationDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm(`<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete ${row.name}?</div>`, '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        await this.addOrUpdateOrganization({ ...row, isDeleted: true });
        this.$message.success({ message: 'success' });
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ message: 'fail' });
        }
      }
    },
    async handleRestore(row) {
      await MessageBox.confirm(`<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Restore ${row.name}?</div>`, '', {
        customClass: "msgBox",
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true
      });
      await this.addOrUpdateOrganization({ ...row, isDeleted: false });
      this.$message.success({ message: 'success' });
    }
  },
  async mounted() {
    this.fetchData()
  }
}
</script>
<style scoped>
.table-content::before {
  height: 0px;
}

.indicator-wrapper {
  display: flex;
  align-items: center;
}

.status-text {
  margin-left: 10px;
}

.status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}

.organization-list {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.table-card {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.table-card .operation-panel {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

.header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-title {
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.username-text {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 500;
  color: #555555;
  line-height: 22px;
}

.update-button {
  margin-left: 37px;
  width: 88px;
  height: 36px;
  padding-top: 0;
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
  color: #555555;
}

.table-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

.table-header-section .filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
  color: rgba(86, 94, 109, 1);
}

.table-header-section .filters>*>span {
  margin-right: 14px;
}

.table-header-section .el-button+.el-button {
  margin-left: 0;
}

.table-text {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}

.table-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  padding: 0 20px;
  overflow: hidden;
}

.table-content {
  flex: 1;
}

.operation-wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.status-operation-button {
  width: 70px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.operation-button {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
}

.button-divider {
  width: 1px;
  height: 14px;
  background: #E9E9E9;
  margin-left: 14px;
  margin-right: 14px;
}

.pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px;
}
</style>
