import axios from "axios";
import { EventBus } from "./event-bus.js";

// const API_URL_BASE = 'http://localhost:8080/v1';
// const API_URL_BASE = 'http://localhost:30080/v1';
// const API_URL_BASE = 'http://10.10.21.28:30080/v1';
const API_URL_BASE = process.env.VUE_APP_API_URL_BASE;

// TODO 需要修改 ----------------------------------------------------------------------------------------------------
// const API_URL_BASE = 'https://api.cereb.ai/v1';

// const API_URL_BASE = 'http://platform-demo-v2.cereb.ai/#/';
// const TIMEZONE = 'Asia/Hong_Kong'

const instance = axios.create({
  baseURL: API_URL_BASE,
  timeout: 60000,
  withCredentials: true,
});

// ==========>请求拦截
instance.interceptors.request.use(
  (config) => {
    // 请求时将token加入到headers
    if (localStorage.token) {
      config.headers["Authorization"] = "Bearer " + localStorage.token;
    }
    return config;
  },
  (error) => {
    console.log("request err: " + error);
    return Promise.reject(error);
  }
);

// ==========>响应拦截
instance.interceptors.response.use(
  (response) => {
    if ((response.status === 400 || response.status === 403) && response.data) {
      throw response.data;
    }
    return response.data;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // 401 清除localStorage中的token
      // localStorage.removeItem('token');
      // 跳转登录页
      EventBus.$emit("logout");
      return Promise.reject("unauthorized");
    }
    console.log("response err: " + error);
    return Promise.reject(error);
  }
);

export function login(username, password) {
  return instance.post("/login/username", {
    username: username,
    password: password,
  });
}

export function getAdminPlatformUsers({
  username,
  status,
  offset,
  limit,
} = {}) {
  let params = {
    username,
    status,
    offset,
    limit,
  };
  return instance.get("/admin/adminPlatform/users", {
    params,
  });
}

export function updateUserDetail(userDetail) {
  return instance.post("/admin/adminPlatform/users", userDetail);
}

export function updateStatus(id, status) {
  let params = {
    status: status,
  };
  return instance.post("/admin/users/" + id + "/status", {}, { params });
}

export function deleteUser(id) {
  return instance.delete("/admin/adminPlatform/users/" + id);
}

export function createOrUpdateOrganization(organization) {
  //{id:'abc123', name: "test", description: "test"}
  return instance.post("/fission/auth-center/organizations", organization);
}

/** 获取指定组织的用户 */
export async function getUsersInOrganization(organizationId) {
  const result = await instance.get("/fission/auth-center/users", {
    params: { organizationId, offset: 1, limit: Number.MAX_SAFE_INTEGER },
  });
  return result["data"]["list"] || [];
}

/** 更新用户所属organization */
export function updateUsersInOrganization(organizationId, users) {
  users = users.map((user) => {
    return {
      username: user.username,
      alias: user.alias,
      role: "manager",
      email: user.email,
      creationTime: user.creationTime,
      expirationTime: user.expirationTime,
      organizationId,
    };
  });

  return instance.post("/fission/auth-center/users", users);
}

export async function getOrganizations() {
  const result = await instance.get("/fission/auth-center/organizations");
  return result["organizations"] || [];
}

export function deleteOrganization(organizationId) {
  return instance.delete("/fission/auth-center/organizations", {
    params: { organizationId },
  });
}
